const client = require('client-oauth2')
const popsicle = require('popsicle')

const auth = new client({
  clientSecret: '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',
  clientId: '65326525836-7pmreiff4il4t48o060k4n57c9ktco6a.apps.googleusercontent.com',
  accessTokenUri: 'https://www.googleapis.com/oauth2/v4/token',
  authorizationUri: 'https://accounts.google.com/o/oauth2/v2/auth',
  redirectUri: typeof window !== 'undefined'? window.location.origin + '/callback/' : 'https://localhost:8000/callback',
  scopes: ['openid', 'email', 'profile'],
})

const cachedUser = () => {
  const userData = sessionStorage.getItem("user")
  if(userData) {
    return JSON.parse(userData)
  } else {
    return null
  }
}

export const logout = () => {
  sessionStorage.clear()
}

export const login = async () => {
  const user = cachedUser()
  if(user) {
    return {
      user: user,
      next: '',
    }
  } else {
    if (window.location.pathname.startsWith('/callback/')) {
      const token = await auth.code.getToken(window.location.href)

      const req = token.sign({
        method: 'get',
        url: `https://www.googleapis.com/oauth2/v3/userinfo`
      })

      const res = await popsicle.fetch(req.url, {headers: req.headers})

      if (res.status === 200) {
        const json = await res.text()
        const googleUser = JSON.parse(json)
        const user = {
          username: googleUser.email.substring(0, googleUser.email.indexOf("@")),
          name: googleUser.name,
          domain: googleUser.hd,
        }
        sessionStorage.setItem("user", JSON.stringify(user))
        return {
          user: user,
          next: sessionStorage.getItem('after_auth_page'),
        }
      } else {
        console.error('auth:', 'user lookup failed', res.status)
      }
    } else {
      console.log('auth:', 'not at callback, simple redirect for oauth token')
      sessionStorage.setItem('after_auth_page', window.location.pathname)
    }

    window.location = auth.code.getUri()
  }
}
