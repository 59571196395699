import React from "react"

import {UserProvider} from "../context/user"
import {navigate} from "gatsby"
import {login} from "../services/auth"
import {findEmployee} from "../../employees"
import Section from '../components/section'

import 'bootstrap/dist/css/bootstrap.min.css'

// Needs to be updated each quarterly.
const baseCompletionFlags = '.completionFlags.v17'

class App extends React.Component  {
  constructor(props) {
    super(props)

    this.setCompletionFlag = (id) => {
      let newState = Object.assign({}, this.state);
      newState.completionFlags[id] = true
      this.setState(newState);
      localStorage.setItem(newState.username+baseCompletionFlags, JSON.stringify(newState.completionFlags))
    }

    this.getCompletionFlag = (id) => {
      return !!this.state.completionFlags[id]
    }

    this.setHasReviewed = (id) => {
      this.setCompletionFlag("Reviewed."+id)
    }

    this.getHasReviewed = (id) => {
      return this.getCompletionFlag("Reviewed."+id)
    }

    this.state = {
      username: "",
      oauth: null,
      employee: null,
      manager: null,
      isEmployee: false,
      completionFlags: {},
      setCompletionFlag: this.setCompletionFlag,
      getCompletionFlag: this.getCompletionFlag,
      setHasReviewed: this.setHasReviewed,
      getHasReviewed: this.getHasReviewed,
    }
  }

  componentDidMount() {
    login()
      .then( (result) => {
        if(result) {
          const user = result.user
          const employee = findEmployee(user.username)
          const manager = employee? findEmployee(employee.manager) : null
          this.setState({
            username: user.username,
            oauth: user,
            employee: employee,
            manager: manager,
            isEmployee: !!employee,
            completionFlags: JSON.parse(localStorage.getItem(user.username+baseCompletionFlags)) || {},
          })
          if(result.next && result.next !== "") {
            navigate(result.next)
          }  
        }
      })
  }

  render() {
    const { children } = this.props

    const authorized = <UserProvider value={this.state}><main>{children}</main></UserProvider>
    const unauthorized = <p>Unauthorized. Please login with your Certain Affinity account.</p>
    const loading = <p>...</p>

    let content = loading

    if(this.state && this.state.oauth) {
      if(this.state.oauth.domain === "certainaffinity.com") {
        content = authorized
      } else {
        content = unauthorized
      }
    }

    return (
      <Section>
        {content}
      </Section>
    )
  }
}

export default App
