import React from "react";

const Section = ({ children }) => {
  return (
    <div style={{
      margin: `auto auto`,
      maxWidth: 960,
      padding: `1.20rem 1.20rem 1.20rem`,
    }}>
      {children}
    </div>
  )
}

export default Section