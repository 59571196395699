import React from "react"

const UserContext = React.createContext({
  loggedIn: false,
  username: "",
  employee: null,
  manager: null,
  oauth: null,
  isEmployee: false,
  completionFlags: {},
  setCompletionFlag: (id) => {},
  getCompletionFlag: (id) => {},
  setHasReviewed: (id) => {},
  getHasReviewed: (id) => {},
})

export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer
export default UserContext
