let employees = require('./employee-data.json');

employees.sort((a,b) => {
  if(a.name < b.name) {
    return -1
  } else if(a.name > b.name) {
    return 1
  } else {
    return 0
  }
})

const findEmployee = (id) => {
  for(let i=0; i<employees.length; i++) {
    if(employees[i].id === id) {
      return employees[i]
    }
  }
  return null
}

let managers = {}

for(let i=0; i<employees.length; i++) {
  let m = managers[employees[i].manager]
  if(!m) {
    let mgr = findEmployee(employees[i].manager)
    managers[employees[i].manager] = mgr
  }
}

const findEmployeesForManager = (id) => {
  console.log("id: " + id)
  console.log("employees.length: " + employees.length)

  let result = []
  for(let i=0; i<employees.length; i++) {
    if(employees[i].manager === id && id !== employees[i].id) {
      result.push(employees[i])
    }
  }

  console.log("results:")
  console.log(result)
  return result
}

if(typeof window === 'undefined') {
  console.log("Alias,Manager,Department,Name,Nickname,Peer Review Link")

  employees.forEach(function(e) {
    console.log(e.id + "," + e.manager + "," + e.department + "," + e.name + "," + e.nickname +",https://quarterlies.spacerouter.app/" + e.id)
  });
  
  console.log("Employees loaded!")
}

module.exports.findEmployee = findEmployee
module.exports.findEmployeesForManager = findEmployeesForManager
module.exports.allEmployees = employees
